<template>
  <div>
    <div
      class="d-flex justify-content-center align-items-center"
      v-if="
        showPreloader ||
        $store.state.refresh.fetchingNewData ||
        $store.state.refresh.waiting_filter
      "
      style="height: 90vh"
    >
      <b-spinner variant="primary" label="Text Centered" />
    </div>
    <div v-else>
      <table-street
        :dataTable="streets"
        @editForm="editForm"
        @refresh="refresh"
      />
    </div>
    <modal-street @refresh="refresh" />
    <modal-street-edit :id="id" @refresh="refresh" />
    <filter-modalVue
      :fields="fields"
      @sendToParent="sendToParent"
    ></filter-modalVue>
  </div>
</template>
<script>
import tableStreet from "@/views/component/Table/tableStreet.vue";
import modalStreet from "@/views/component/Modal/ModalStreet/modalStreet.vue";
import modalStreetEdit from "@/views/component/Modal/ModalStreet/modalStreetEdit.vue";
export default {
  components: {
    tableStreet,
    modalStreet,
    modalStreetEdit,
  },
  data() {
    return {
      id: "",
      streets: [],
      showPreloader: false,
      district: {},
      fields: [
        { key: "checkbox", label: "", thStyle: { width: "10px" } },
        { key: "id", label: "Id", sortable: true, thStyle: { width: "20px" } },
        {
          key: "name",
          label: "Улица",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "village",
          label: "Население пукт",
          sortable: true,
          thStyle: { width: "150px" },
        },
        { key: "is_active", label: "Статус" },
      ],
    };
  },
  mounted() {
    this.$store.commit("pageData/setdataCount", null);
    this.openFilter();
  },
  methods: {
    editForm(id) {
      this.id = id;
      setTimeout(this.openModal, 0);
    },
    openModal() {
      this.$bvModal.show("streetModalEdit");
    },
    openFilter() {
      let allRoutes = this.$store.state.draggableTab.tabs;
      let param = JSON.parse(localStorage.getItem(this.$route.name + "data"));
      let arrayRoutes = [];
      allRoutes.forEach((element) => {
        arrayRoutes.push(element.path);
      });
      if (arrayRoutes.includes(this.$route.path) == true) {
        this.showPreloader = true;
        this.$http.get(`${this.$route.name}`, { params: param }).then((res) => {
          this.streets = res.data;
          this.$store.commit("pageData/setdataCount", this.streets.length);
          this.showPreloader = false;
          this.$store.commit("REFRESH_DATA", false);
        });
      } else if (arrayRoutes.includes(this.$route.path) == false) {
        this.$bvModal.show(this.$route.name + "filter");
      }
    },
    refresh() {
      this.showPreloader = true;
      this.$http.get("addresses/streets").then((res) => {
        this.streets = res.data;
        this.$store.commit("pageData/setdataCount", this.streets.length);
        this.showPreloader = false;
        this.$store.commit("REFRESH_DATA", false);
      });
    },
    sendToParent(tableData) {
      this.streets = tableData;
      this.$store.commit("pageData/setdataCount", this.streets.length);
    },
  },
  computed: {
    fetchingNewData() {
      return this.$store.state.refresh.fetchingNewData;
    },
  },
  watch: {
    fetchingNewData(val) {
      if (val) {
        this.$http.get("addresses/streets").then((res) => {
          this.streets = res.data;
          this.$store.commit("pageData/setdataCount", this.streets.length);
          this.showPreloader = false;
          this.$store.commit("REFRESH_DATA", false);
        });
      }
    },
  },
};
</script>
